import { useState } from "react";
import { Image } from "./images";

function Card(props) {
  const [isLiked, setIsLiked] = useState(props.item.likes?.some(i => i === props.currentUser?._id));

  const isOwn = props.item.owner === props.currentUser?._id;

  function handleLikeClick(card) {
    props.onCardLike(card)
    setIsLiked(!isLiked);
  }

  function handleDeleteClick(card) {
    props.onCardDelete(card);
  }

  return(
    <div class="card">
      <div class="elements__content">
        <div class={`deleteCard ${isOwn ? "" : 'displayNone'} `} onClick={()=>handleDeleteClick(props.item)}></div>
        <div class="elements__content-container" onClick={()=>props.onClick(props.item)}>
          <Image src={props.item.link}/>
        </div>
        <div class="elements__content-text">
          <h3>{props.item.name}</h3>
          <div>
          <button class={`elements__button-like ${isLiked ? 'elements__button-likeBlack' : ''}`} onClick={()=>handleLikeClick(props.item)}>
            </button>
            <div class="like" id="numberLike">{props.item.likes?.length}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;