import AddButtonImage from '../pages/images/AddButton.png'
import AvatarImage from '../pages/images/Avatar.png'
import CloseIconImage from '../pages/images/CloseIcon.png'
import EditImage from '../pages/images/edit.png'
import EditButtonImage from '../pages/images/EditButton.png'
import HeartImage from '../pages/images/heart.png'
import HeartBlackImage from '../pages/images/heartBlack.png'
import KirillImage from '../pages/images/kirill.jpg'
import LixeiraImage from '../pages/images/lixeira-de-reciclagem.png'
import VectorImage from '../pages/images/Vector.png'
import CorrectImage from '../pages/images/correct.png'
import ErrorImage from '../pages/images/error.png'

export function AddButton() {
  return(
    <>
      <img src={AddButtonImage} alt="Imagem do Add Button"/>
    </>
  );
}

export function Avatar(props) {
  return(
    <>
       <img src={props.src} alt="Avatar" style={{width: '130px', height: '130px', borderRadius: '100%', objectFit: 'cover'}}/>
    </>
  );
}


export function CloseIcon() {
  return(
    <>
       <img src={CloseIconImage} alt="CloseIcon"/>
    </>
  );
}


export function Edit() {
  return(
    <>
       <img src={EditImage} alt="Edit"/>
    </>
  );
}


export function EditButton() {
  return(
    <>
       <img src={EditButtonImage} alt="EditButton"/>
    </>
  );
}


export function Heart() {
  return(
    <>
       <img src={HeartImage} alt="Heart"/>
    </>
  );
}

export function HeartBlack() {
  return(
    <>
       <img src={HeartBlackImage} alt="HeartBlack"/>
    </>
  );
}

export function Image(props) {
  return(
    <>
       <img src={props.src} alt="Kirill" class="elements__content-img"/>
    </>
  );
}

export function Lixeira() {
  return(
    <>
       <img src={LixeiraImage} alt="Lixeira"/>
    </>
  );
}

export function Vector() {
  return(
    <>
       <img src={VectorImage} alt="Vector" width={'200px'} height={'40px'}/>
    </>
  );
}

export function Correct() {
  return(
  <>
    <img src={CorrectImage} alt="Vector" width={'200px'} height={'200px'}/>
  </>
  );
}

export function Error() {
  return(
  <>
    <img src={ErrorImage} alt="Vector" width={'200px'} height={'200px'}/>
  </>
  );
}