import { CloseIcon } from "./images";

function PopupWithForm(props) {
  return(
    <>
      <div className={`opacity ${props.isOpen ? 'popup_opened' : ''}`} id={props.id}>
        <div className="content-modal">
          <button className="buttonClose" id="buttonClose" onClick={props.onClose}>
            <CloseIcon/>
          </button>
          <div className="modal">
            <form className="formEdit form" onSubmit={props.onSubmit}>
              <h3>{props.title}</h3>
              {props.children}
              <button type="submit" className="modal__button-save" id="buttonSave"> {props.button} </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupWithForm;