import { CloseIcon } from "./images";

function ImagePopUp(props) {
  return(
    <>
      <div className={`opacity ${props.isOpen ? 'popup_opened' : ''}`} id="modalImage">
        <div className="content-modal">
          <button className="buttonClose" id="buttonClose" onClick={props.onClose}>
            <CloseIcon/>
          </button>
       
              <img src={props.card?.link}/>          
              <h3 className="nameModal">{props.card?.name}</h3>
          
        </div>
      </div>
    </>
  );
}

export default ImagePopUp;