export function login(email, password) {
  return fetch('http://34.42.216.169:3000/signin', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password
    })
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Falha na requisição: ' + response.status);
    }
    // Resolve a promessa retornada por response.json()
    return response.text();
  })
  .then((data) => {
    localStorage.setItem('token', data);
    return true;
  })
  .catch((error) => {
    console.error('Erro:', error);
    return false;
  });
}


export function register(email, password){
  return fetch('http://34.42.216.169:3000/signup', {
    method: 'post',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password
    })
  }).then((response)=>{
    return true;
  }).catch(()=>{
    return false;
  })
}

export function getWhoIAm(){
  const token = localStorage.getItem('token');
  return fetch('http://34.42.216.169:3000/users/me', {
    method: 'get',
    headers: {
      "Content-Type": "application/json",
       "Authorization" : `Bearer ${token}`
    }
  }).then(
    (response) =>{
      return response.email;
    }
  );
}