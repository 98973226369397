import { CloseIcon, Correct, Error } from "./images";
import '../pages/blocks/modal.css'

function ModalRegisterError(props) {
  return(
    <>
      <div className={`opacity ${props.isOpen ? 'popup_opened' : ''}`} id={props.id}>
        <div className="content-modal">
          <button className="buttonClose" id="buttonClose" onClick={props.onClose}>
            <CloseIcon/>
          </button>
          <div className="modal modalRegister">
            <Error />
            <h1>Ops, algo saiu deu errado! 
            Por favor, tente novamente.</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalRegisterError;