import { Vector } from "./images";

function Header(props) {
  return(
    <>
     <header>
        <section className="section">
          <div class="section-header">
            <Vector/>
            <div className="header-text">
              <p>{props.text}</p>
              {props.isLoggedIn && <p>Sair</p>}
            </div>
          
          </div>
          <div class="header-line"></div>
        </section>
      </header>
    </>
  );
}

export default Header;