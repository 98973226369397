import Header from "./Header";
import '../pages/blocks/main.css';
import React, { useRef, useState } from 'react';
import { login } from "../utils/auth";
import ModalRegister from "./ModalRegister";
import ModalRegisterError from "./ModalRegisterError";

function Login() {
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  async function handleSubmit(event) {
    event.preventDefault(); 
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (email && password) {
      const result = await login(email, password);
        if (result === true) {
          setModal(true);
          window.location.assign("/")
        } else {
          setModalError(true);
        }
    } else {
      alert("Preencha todos os campos.");
    }
  }

  return (
    <>
      <Header text="entrar" isLoggedIn={false} />
      <main>
        <form className="content-login" onSubmit={handleSubmit}>
          <h1 className="title-login">Entrar</h1>
          <input 
            type="email" 
            ref={emailRef} 
            className="input-login" 
            placeholder="Email:" 
            required
          />
          <input 
            type="password" 
            ref={passwordRef} 
            className="input-login" 
            placeholder="Senha:" 
            required
          />
          <button type="submit" className="btn-enviar">Entrar</button>
          <p className="content-login__text">
            Ainda não é membro? <a href="./register" className="link">Inscreva-se aqui!</a>
          </p>
        </form>
      </main>
      <ModalRegister isOpen={modal} onClose={() => setModal(false)} />
      <ModalRegisterError isOpen={modalError} onClose={() => setModalError(false)} />
    </>
  );
}

export default Login;
