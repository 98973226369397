class Api {
  constructor() {

  }

  getInitialCards() {
    const token = localStorage.getItem('token');
      return fetch("http://34.42.216.169:3000/cards", {
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${token}`
        }
        })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
          })
          .catch((err) => {
            console.log(err); 
          });
  }

  getUserInfo() {
    const token = localStorage.getItem('token');
      return fetch("http://34.42.216.169:3000/users/me", {
          headers: {
            "Content-Type": "application/json",
            "Authorization" : `Bearer ${token}`
          }
        })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
          })
          .catch((err) => {
            console.log(err); 
          });
  }

  setUserInfo(name, about) {
    const token = localStorage.getItem('token');
      return fetch("http://34.42.216.169:3000/users/me",{
          method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`
              },
              body: JSON.stringify({
                  name: name,
                  about: about
              })
      })
      .catch((err) => {
        console.log(err); 
      });
  }

  setCard(name, link){
    const token = localStorage.getItem('token');
      return fetch("http://34.42.216.169:3000/cards", {
          method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization" : `Bearer ${token}`
            },
              body: JSON.stringify({
                  name: name,
                  link: link
              })
      })
      .catch((err) => {
        console.log(err); 
      });
  }

  deleteCard(id){
    const token = localStorage.getItem('token');
    return fetch(`http://34.42.216.169:3000/cards/${id}`,{
      method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${token}`
        },
    })
    .catch((err) => {
      console.log(err); 
    });
  }

  setLike(id){
    const token = localStorage.getItem('token');
    return fetch(`http://34.42.216.169:3000/cards/${id}/likes`,{
      method: "PUT",
        headers: {
              "Content-Type": "application/json",
              "Authorization" : `Bearer ${token}`
            },
    })
    .catch((err) => {
      console.log(err); 
    });
  }

  deleteLike(id){
    const token = localStorage.getItem('token');
    return fetch(`http://34.42.216.169:3000/cards/${id}/likes`,{
      method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${token}`
        },
    })
    .catch((err) => {
      console.log(err); 
    });
  }

  setAvatar(url){
    const token = localStorage.getItem('token');
    return fetch("http://34.42.216.169:3000/users/me/avatar",{
      method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization" : `Bearer ${token}`
        },
        body: JSON.stringify({
          avatar: url
      })
    })
    .catch((err) => {
      console.log(err); 
    });
  }

}

export default new Api();

