import api from "../utils/api";
import Card from "./Card";
import ImagePopUp from "./ImagePopup";
import { AddButton, Avatar, CloseIcon, Edit, EditButton, Kirill } from "./images";
import PopupWithForm from "./PopupWithForm";
import React, { useState, useEffect } from 'react';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { CardContext } from "../contexts/CardContext";
import EditProfilePopup from "./EditProfilePopup";

function Main(props) {
  const userInfo = React.useContext(CurrentUserContext);

  return(
    <>
      <main>
        <section class="main-page">
          <div class="main-page__content">
            <div class="main-page__photo-avtar">
              <Avatar src={userInfo?.avatar}/>
            </div>
            <div class="editAvatar" onClick={props.onEditAvatarClick}>
              <Edit />
            </div>
            <div>
              <div class="main-page__content-text"> 
                <div class="main-page__text-img">
                  <h2 id="name" class="text"> {userInfo?.name} </h2>
                  <button class="button" id="ButtonEdit" onClick={props.onEditProfileClick}>
                    <EditButton />
                  </button>
                </div>
                  <p id="aboutMe" class="text">{userInfo?.about}</p>
              </div>
            </div>
          </div>
          <div class="main-page__content-img-AddButton" id="ButtonAddCard" onClick={props.onAddPlaceClick}>
            <AddButton/>
          </div>
        </section>
        
        <section class="elements">
          {props.cards.map((card)=><Card onClick={props.onCardClick} item={card} currentUser={userInfo} onCardLike={props.onCardLike} onCardDelete={props.onCardDelete}/>)}
        </section>
      </main>

      <PopupWithForm id="modalDeleteCard" title="Tem certeza?" button="SIM" onClose={props.onClose}>

      </PopupWithForm>

      <ImagePopUp card={props.selectedCard} onClose={props.onClose} isOpen={props.selectedCard != null}/>

    </>
  );
}

export default Main;