import { useRef } from "react";
import PopupWithForm from "./PopupWithForm";

function EditAvatarPopup(props) {
  const avatarRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();
  
    const avatarLink = avatarRef.current.value;
    props.onUpdateAvatar(avatarLink);
  }

  return(
      <PopupWithForm title="Alterar a foto de perfil" button="Salvar" id="modalAvatar" isOpen={props.isEditAvatarPopupOpen} onClose={props.onClose} onSubmit={handleSubmit}>
        <input type="text" placeholder="Link" class="modal__input" id="inputAvatar" ref={avatarRef} required />
        <p class="Alert-text"></p>
      </PopupWithForm>
  );
}

export default EditAvatarPopup;