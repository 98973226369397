import './pages/styles/index.css';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import React, { useEffect, useState } from 'react';
import './App.css'
import { CurrentUserContext } from './contexts/CurrentUserContext';
import api from './utils/api';
import { CardContext } from './contexts/CardContext';
import EditProfilePopup from './components/EditProfilePopup';
import EditAvatarPopup from './components/EditAvatarPopup';
import AddPlacePopup from './components/AddPlacePopup';
import { getWhoIAm } from './utils/auth';


function Home() {
  const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = useState(false);
  const [isAddPlacePopupOpen, setIsAddPlacePopupOpen] = useState(false);
  const [isEditAvatarPopupOpen, setIsEditAvatarPopupOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [currentUser, setCurrentUser] = useState('');

  const [cards, setCards] = useState([]);

  const [email, setEmail] = useState();


  useEffect(()=>{
    api.getInitialCards().then((initialCards)=>{ setCards(initialCards); })  
    api.getUserInfo().then((userInfo)=>{ setCurrentUser(userInfo); })
  },[]);

  function handleEditAvatarClick() {
    setIsEditAvatarPopupOpen(true);
  }

  function handleEditProfileClick() {
    setIsEditProfilePopupOpen(true);
  }

  function handleAddPlaceClick() {
    setIsAddPlacePopupOpen(true);
  }

  function handleCardClick(item){
    setSelectedCard(item);
  }

  function closeAllPopups(){
    setIsAddPlacePopupOpen(false);
    setIsEditProfilePopupOpen(false);
    setIsEditAvatarPopupOpen(false);
    setSelectedCard(null);
  }

  function handleUpdateUser(newUser){
    api.setUserInfo(newUser.name, newUser.about).then(()=>{
      setCurrentUser({...currentUser,name: newUser.name, about: newUser.about});
      closeAllPopups();
    });
  }

  function handleUpdateAvatar(url){
    api.setAvatar(url).then(()=>{
      setCurrentUser({...currentUser, avatar:url})
      closeAllPopups();
    })
  }

  function handleCardDelete(card){
    api.deleteCard(card._id).then((newCard) => {
      setCards(prevCards => prevCards.filter(c => c._id !== card._id));
  });
  }


  function handleCardLike(card) {
    // Verifique mais uma vez se esse cartão já foi curtido
    const isLiked = card.likes.some(i => i._id === currentUser._id);
    
    if(isLiked){
      api.deleteLike(card._id).then((newCard) => {
        setCards(prevCards => prevCards.map(c => 
          c._id === card._id ? { ...c, likes: c.likes.filter(i => i._id !== currentUser._id) } : c
        ));
        
    });
    }else {
      api.setLike(card._id).then((newCardData) => {
        setCards(prevCards => prevCards.map(c => 
          c._id === card._id ? { ...c, likes: [...c.likes, { _id: currentUser._id }] } : c
        ));
    });
    }
  }

  function handleAddPlace(name, link) {
    api.setCard(name, link).then((data)=>{
      return data.json();
    }).then((card)=>{
      setCards([card, ...cards]);
    })
    closeAllPopups();
  }

  console.log('card', cards)

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <CardContext.Provider value={cards}>
        <div class="pages">
          <Header text={email} isLoggedIn={true}/>
          <Main 
            onEditProfileClick={handleEditProfileClick} 
            onAddPlaceClick={handleAddPlaceClick} 
            onEditAvatarClick={handleEditAvatarClick} 
            onCardClick={handleCardClick}
            selectedCard={selectedCard}
            isEditProfilePopupOpen={isEditProfilePopupOpen}
            isAddPlacePopupOpen={isAddPlacePopupOpen}
            isEditAvatarPopupOpen={isEditAvatarPopupOpen}
            onClose={closeAllPopups}
            cards={cards || []}
            onCardLike={handleCardLike}
            onCardDelete={handleCardDelete}
          />
          <Footer/>
        </div>
      <EditAvatarPopup isEditAvatarPopupOpen={isEditAvatarPopupOpen} onClose={closeAllPopups} onUpdateAvatar={handleUpdateAvatar}/>
      <EditProfilePopup isEditProfilePopupOpen={isEditProfilePopupOpen} onClose={closeAllPopups} onUpdateUser={handleUpdateUser}/>
      <AddPlacePopup isAddPlacePopupOpen={isAddPlacePopupOpen} onClose={closeAllPopups} onAddPlace={handleAddPlace}/>

      </CardContext.Provider>
    </CurrentUserContext.Provider>   
  );
}

export default Home;
