function Footer() {
  return(
    <>
      <footer class="footer">
        <p>&copy; 2024 Around The U.S.</p>
      </footer>
    </>
  );
}

export default Footer;