import React, { useState } from "react";
import PopupWithForm from "./PopupWithForm";
import { CurrentUserContext } from "../contexts/CurrentUserContext";

function EditProfilePopup(props) {
  const [name , setName] = useState();
  const [description, setDescription] = useState();

  const currentUser = React.useContext(CurrentUserContext);

  React.useEffect(() => {
    setName(currentUser?.name);
    setDescription(currentUser?.about);
  }, [currentUser]);

  function handleSubmit(e) {
     e.preventDefault();
  
     props.onUpdateUser({
       name,
       about: description,
     });
   }

  return(
    <>
      <PopupWithForm title='Editar perfil' button="Salvar" id="modal" isOpen={props.isEditProfilePopupOpen} onClose={props.onClose} onSubmit={handleSubmit}>
        <input type="text" placeholder="Nome" class="modal__input formField" id="inputName" value={name} required onChange={(e)=>setName(e.target.value)}/>
        <p id="Alert-text" class="Alert-text"></p>
        <input type="text" placeholder="Sobre Mim" class="modal__input-About formField" id="inputAboutMe" value={description} required  onChange={(e)=>setDescription(e.target.value)}/>
        <p id="AlertAbout" class="Alert-text"></p>
      </PopupWithForm>
    </>
  );
}

export default EditProfilePopup;