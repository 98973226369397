import { CloseIcon, Correct } from "./images";
import '../pages/blocks/modal.css'

function ModalRegister(props) {
  return(
    <>
      <div className={`opacity ${props.isOpen ? 'popup_opened' : ''}`} id={props.id}>
        <div className="content-modal">
          <button className="buttonClose" id="buttonClose" onClick={props.onClose}>
            <CloseIcon/>
          </button>
          <div className="modal modalRegister">
            <Correct />
            <h1>Vitória! Você precisa se registrar</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalRegister;