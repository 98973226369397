import { useRef } from "react";
import PopupWithForm from "./PopupWithForm";

function AddPlacePopup(props) {
  const nameRef = useRef();
  const linkRef = useRef();

  function handleAddPlaceSubmit(e){
    e.preventDefault();

    const name = nameRef.current.value;
    const link = linkRef.current.value;
    props.onAddPlace(name, link);
  }

  return(
    <>
      <PopupWithForm title='Novo local' button={'Salvar'} id="modalAddCard" isOpen={props.isAddPlacePopupOpen} onClose={props.onClose} onSubmit={handleAddPlaceSubmit}>
        <input type="text" placeholder="Titulo" class="modal__input" id="inputCardTitle" required ref={nameRef}/>
        <p id="localName" class="Alert-text"></p>
        <input type="url" placeholder="Link da imagem" class="modal__input-About" id="inputLinkImage" required ref={linkRef}/>
        <p id="linkImage" class="Alert-text"></p>
      </PopupWithForm>
    </>
  );
}

export default AddPlacePopup;