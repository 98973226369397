import Header from "./Header";
import React, { useRef, useState } from 'react';
import { register } from "../utils/auth";
import ModalRegister from "./ModalRegister";
import ModalRegisterError from "./ModalRegisterError";

function Register() {
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  async function handleSubmit (event) {
    event.preventDefault(); 

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    const result = await register(email, password);
    if (result === true) {
      setModal(true);
    } else {
      setModalError(true);
    }
  }

  return (
    <>
      <Header text="Faça o login" isLoggedIn={false} />
      <main>
        <form className="content-login" onSubmit={handleSubmit}>
          <h1 className="title-login">Inscreva-se</h1>
          <input 
            type="email" 
            ref={emailRef} 
            className="input-login" 
            placeholder="Email:" 
            required
          />
          <input 
            type="password" 
            ref={passwordRef} 
            className="input-login" 
            placeholder="Senha:" 
            required
          />
          <button type="submit" className="btn-enviar">Inscrever-se</button>
          <p className="content-login__text">
            Já é membro? <a href="./login" className="link">Faça Login aqui!</a>
          </p>
        </form>
      </main>
      <ModalRegister isOpen={modal} onClose={() => setModal(false)} />
      <ModalRegisterError isOpen={modalError} onClose={() => setModalError(false)} />
    </>
  );
}

export default Register;
